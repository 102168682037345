import { UploadProgress } from '../contexts/UploadProgressContext';
import instance from './instance';
import timeoutInstance from './timeoutInstace';
import {
    ComplianceDataResponse,
    AssetDetailResponse,
    FetchComplianceDataParams,
    FileUploadItem,
    UploadResult,
    UploadStatusResponse,
    AnalyticsDataResponse,
} from '../types';

import axios from 'axios';
import { MutableRefObject } from 'react';
import { LibraryContextType } from '../contexts/LibraryContext';
import { AnalyticsContextType } from '../contexts/AnalyticsContext';
import { rotateImage } from './handleImage';

export const fetchAccessToken = async (
    code: string,
    type: 'login' | 'register' | 'reset_password',
) => {
    try {
        const response = await instance.get(
            `/access_token?code=${code}&type=${type}`,
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const validateToken = async () => {
    try {
        await instance.get('/validate_token');
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchItemDetail = async (
    id: string,
): Promise<AssetDetailResponse> => {
    try {
        const response = await instance.get(`/assets/${id}`);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Item not found');
        }
    } catch (error) {
        console.error('Error fetching item detail:', error);
        throw error;
    }
};

export const submitFeedbackForItem = async (
    id: string,
    ruleId: string,
    feedbackdata: {
        comment: string;
        new_outcome: string;
    },
): Promise<AssetDetailResponse> => {
    // todo update to pass in actual item id
    try {
        const response = await instance.post<AssetDetailResponse>(
            `/feedback/${id}/${ruleId}`,
            feedbackdata,
        );
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Item not found');
        }
    } catch (error) {
        console.error('Error updating item feedback:', error);
        throw error;
    }
};

export const deleteAsset = async (asset_id: string) => {
    // todo update to pass in actual item id
    try {
        const response = await instance.post(`/assets/${asset_id}/delete`);
        if (response.data) {
            return response.data;
        } else {
            throw new Error('Item not found');
        }
    } catch (error) {
        console.error('Error deleting asset:', error);
        throw error;
    }
};

export const fetchConfigData = async () => {
    try {
        const response = await instance.get(`/config`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchUploadStatus = async (): Promise<UploadStatusResponse[]> => {
    try {
        const response = await timeoutInstance.get(`/upload_status`); // todo change back
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const removeImageFromUploadStatus = async (image_id: string) => {
    // todo update to pass in actual item id
    try {
        instance.post(`/upload_status/${image_id}/remove`);
    } catch (error) {
        console.error('Error removing image:', error);
        throw error;
    }
};

export const fetchComplianceData = async ({
    pageNumber = 1,
    sortField = 'id',
    sortOrder = 'ASC',
    search,
    numItems,
    filters,
}: FetchComplianceDataParams): Promise<ComplianceDataResponse> => {
    try {
        const params: Record<string, string | number> = {
            page_number: pageNumber,
            page_size: numItems,
            sort_field: sortField,
            sort_order: sortOrder,
            filters: JSON.stringify(filters),
        };

        if (search) params.search = search;

        const response = await instance.get(`/library`, { params });

        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Error fetching compliance data');
    }
};

export const fetchAnalyticsData = async (
    filters: Record<string, string[]>,
): Promise<AnalyticsDataResponse> => {
    try {
        const params = {
            filters: JSON.stringify(filters),
        };
        const response = await instance.get<AnalyticsDataResponse>(
            `/analytics`,
            {
                params,
            },
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Error fetching compliance data');
    }
};

export const fetchAndSetAnalyticsData = async (
    analyticsContext: AnalyticsContextType,
) => {
    const response = await fetchAnalyticsData(
        analyticsContext.state.appliedFilters,
    );
    analyticsContext.setters.setFilterCountRecord(response.filter_count_dict);
    analyticsContext.setters.setAnalyticsData({
        userAnalytics: response.user_outcome_dict.user,
        outcomeAnalytics: response.user_outcome_dict.outcome,
        ruleFailureAnalytics: response.rule_failure_dict,
    });
};

export const waitForWebSocketMessage = (
    websocket: MutableRefObject<WebSocket | null>,
    imageId: string,
    timeout: number, // default timeout of 30 seconds
): Promise<void> => {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(new Error('Timeout waiting for WebSocket message'));
        }, timeout);

        const messageListener = (event: MessageEvent) => {
            if (event.data === `success, ${imageId}`) {
                clearTimeout(timeoutId);
                websocket.current?.removeEventListener(
                    'message',
                    messageListener,
                );
                resolve();
            } else if (event.data === `error, ${imageId}`) {
                clearTimeout(timeoutId);
                websocket.current?.removeEventListener(
                    'message',
                    messageListener,
                );
                reject(new Error('Error from websocket'));
            }
        };

        websocket.current?.addEventListener('message', messageListener);

        // Handle WebSocket closure
        websocket.current?.addEventListener('close', () => {
            clearTimeout(timeoutId);
            reject(new Error('WebSocket closed before receiving the message'));
        });
    });
};

export const fetchUploadData = async () => {
    const { data } = await instance.get<{ url: string; image_id: string }>(
        '/upload_token',
    );
    return data;
};

// export const uploadAsset = async (
//     imageFile: File,
//     metaData: FileUploadItem,
//     uploadData: { url: string; image_id: string },
//     setUploadProgressArray: (
//         value: React.SetStateAction<UploadProgress[]>,
//     ) => void,
//     uploadProgressArray: UploadProgress[],
//     websocket: MutableRefObject<WebSocket | null>, // Pass the websocket reference
// ) => {
//     // const { data } = await instance.get<{ url: string; image_id: string }>(
//     //     '/upload_token',
//     // );
//     try {
//         console.log('before upload', uploadProgressArray);

//         await axios.put(uploadData.url, imageFile, {
//             headers: { 'x-ms-blob-type': 'BlockBlob' },
//             // onUploadProgress: (progressEvent) => {
//             //     const total = progressEvent.total ?? 1;
//             //     const percentage = Math.round(
//             //         (progressEvent.loaded * 100) / total,
//             //     );
//             //     setUploadProgressArray(
//             //         uploadProgressArray.map((item) =>
//             //             item.imageId === uploadData.image_id
//             //                 ? { ...item, progress: percentage }
//             //                 : item,
//             //         ),
//             //     );
//             // },
//         });
//         console.log('Upload successful');
//         console.log('after upload', uploadProgressArray);
//     } catch (error) {
//         console.error('Upload failed:', error);
//         throw new Error('Image upload failed.');
//     }
//     setUploadProgressArray(
//         uploadProgressArray.map((item) =>
//             item.imageId === uploadData.image_id
//                 ? { ...item, status: 'checking', progress: 0 }
//                 : item,
//         ),
//     );

//     const updateInterval = 100; // 100ms = 0.1 seconds
//     const step = 0.15; // Increment by step every 0.1 seconds

//     const intervalId = setInterval(() => {
//         setUploadProgressArray(
//             uploadProgressArray.map((item) =>
//                 item.imageId === uploadData.image_id
//                     ? {
//                           ...item,
//                           progress: Math.min(item.progress + step, 90),
//                       }
//                     : item,
//             ),
//         );
//     }, updateInterval);
//     try {
//         const result: UploadResult = (
//             await instance.post('/upload_file_meta', metaData)
//         ).data;
//         console.log('response', result);
//         // When the desired message is received, you can update the progress to 100% or any other value you desire.
//         if (result.success && result?.id && result?.outcome) {
//             const fetchedResult: FetchedResult = {
//                 id: result.id,
//                 outcome: result.outcome,
//             };
//             setUploadProgressArray(
//                 uploadProgressArray.map((item) =>
//                     item.imageId === uploadData.image_id
//                         ? {
//                               ...item,
//                               status: 'successful',
//                               progress: 100,
//                               fetchedResult: fetchedResult,
//                           }
//                         : item,
//                 ),
//             );
//         } else {
//             setUploadProgressArray(
//                 uploadProgressArray.map((item) =>
//                     item.imageId === uploadData.image_id
//                         ? { ...item, status: 'error', progress: 100 }
//                         : item,
//                 ),
//             );
//         }
//         // Clear the update interval when the desired message is received
//         clearInterval(intervalId);
//     } catch (error) {
//         clearInterval(intervalId);
//         console.error('Metadata upload failed:', error);
//         setUploadProgressArray((prevProgressArray) =>
//             prevProgressArray.map((item) =>
//                 item.imageId === uploadData.image_id
//                     ? { ...item, status: 'error', progress: 100 }
//                     : item,
//             ),
//         );
//     }

// try {
//     await waitForWebSocketMessage(websocket, metaData.image_id, 60000);

//     // When the desired message is received, you can update the progress to 100% or any other value you desire.
//     setUploadProgress((prevProgress) => ({
//         ...prevProgress,
//         progress: 100,
//         status: 'successful',
//     }));

//     // Clear the update interval when the desired message is received
//     clearInterval(intervalId);
// } catch (error) {
//     // Handle errors here, if needed
//     console.error('Error:', error);
//     setUploadProgress((prevProgress) => ({
//         ...prevProgress,
//         progress: 100,
//         status: 'error',
//     }));
//     // Clear the update interval if an error occurs
//     clearInterval(intervalId);
// }
// };

export const fetchUserInfo = async () => {
    try {
        const response = await instance.get(`/user_info`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const convertSortOrder = (
    order: 'ascending' | 'descending',
): 'ASC' | 'DESC' => {
    switch (order) {
        case 'ascending':
            return 'ASC';
        case 'descending':
            return 'DESC';
        default:
            return 'ASC';
    }
};

export const handleLibraryRequest = async (
    libraryContext: LibraryContextType,
    newPageNumber = 1,
    showSpinner = true,
) => {
    try {
        if (showSpinner) {
            libraryContext.setters.setLibraryIsLoading(true);
        }
        const sortField = libraryContext.state.appliedSortOption.variable;
        const sortOrderValue = libraryContext.state.appliedSortOption.sortOrder;
        const sqlSortOrder = convertSortOrder(sortOrderValue);
        const pageNumber = newPageNumber;
        const params: {
            pageNumber: number;
            numItems: string;
            filters: Record<string, string[]>;
            sortField: string;
            sortOrder: 'ASC' | 'DESC';
            search?: string;
        } = {
            pageNumber: pageNumber,
            numItems: libraryContext.state.numItems.toString(),
            filters: libraryContext.state.appliedFilters,
            sortField: sortField,
            sortOrder: sqlSortOrder,
        };

        if (libraryContext.state.searchString.length > 0) {
            params.search = libraryContext.state.searchString;
        }
        libraryContext.setters.setCurrentPage(pageNumber);
        libraryContext.setters.setAppliedFilters(
            libraryContext.state.appliedFilters,
        );
        const response = await fetchComplianceData(params);
        libraryContext.setters.setComplianceTableState(response.items);
        libraryContext.setters.setPrevPage(response.prev_page || null);
        libraryContext.setters.setNextPage(response.next_page || null);
        libraryContext.setters.setTotalPages(response.total_pages);
        libraryContext.setters.setImageUrls(response.image_urls);
        libraryContext.setters.setFilterCountRecord(response.filter_count_dict);
    } catch (error) {
        console.error(error);
    } finally {
        libraryContext.setters.setLibraryIsLoading(false);
    }
};

export const uploadAsset = async (
    imageFile: File,
    uploadItem: FileUploadItem,
    setUploadProgressArray: (
        value: React.SetStateAction<UploadProgress[]>,
    ) => void,
    uploadProgressArrayRef: React.MutableRefObject<UploadProgress[]>,
    newUploadProgress: UploadProgress,
): Promise<void> => {
    setUploadProgressArray([
        ...uploadProgressArrayRef.current,
        newUploadProgress,
    ]);
    const uploadData = await fetchUploadData();

    try {
        const imageId = uploadData.image_id;
        newUploadProgress.imageId = imageId;
        uploadItem.image_id = imageId;
        setUploadProgressArray(
            uploadProgressArrayRef.current.map((item) =>
                item.imageUrl === newUploadProgress.imageUrl
                    ? { ...item, progress: 0, status: 'upload' }
                    : item,
            ),
        );

        const blob = await rotateImage(imageFile);
        if (blob) {
            await axios.put(uploadData.url, blob, {
                headers: { 'x-ms-blob-type': 'BlockBlob' },
                onUploadProgress: (progressEvent) => {
                    const total = progressEvent.total ?? 1;
                    const percentage = Math.round(
                        (progressEvent.loaded * 100) / total,
                    );
                    setUploadProgressArray(
                        uploadProgressArrayRef.current.map((item) =>
                            item.imageId === uploadData.image_id
                                ? { ...item, progress: percentage }
                                : item,
                        ),
                    );
                },
            });
        } else {
            throw new Error('Blob generation failed.');
        }
        console.log('Upload successful');
    } catch (error) {
        console.error('Upload failed:', error);
        throw new Error('Image upload failed.');
    }
    setUploadProgressArray(
        uploadProgressArrayRef.current.map((item) =>
            item.imageId === uploadData.image_id
                ? {
                      ...item,
                      status: 'checking',
                      progress: 0,
                      timeStamp: new Date(),
                  }
                : item,
        ),
    );

    try {
        await instance.post('/upload_file_meta', uploadItem);
    } catch (error) {
        console.error('Metadata upload failed:', error);
        setUploadProgressArray((prevProgressArray) =>
            prevProgressArray.map((item) =>
                item.imageId === uploadData.image_id
                    ? { ...item, status: 'error', progress: 100 }
                    : item,
            ),
        );
    }
};
