import React from 'react';
import MainApp from './pages/MainApp';
import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import { useDefaultLibraryContext } from './hooks/useDefaultLibraryContext';
import { LibraryContext } from './contexts/LibraryContext';
import { AnalyticsContext } from './contexts/AnalyticsContext';
import { useDefaultAnalyticsContext } from './hooks/useDefaultAnalyticsContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ConfigProvider from './contexts/ConfigProvider';
import RequireAuth from './components/RequireAuth';
import useAxiosInterceptor from './hooks/useAxiosInterceptor';
import { useAuthContext } from './contexts/AuthContext';
import Register from './pages/Register';

const App = () => {
    useAxiosInterceptor();

    const defaultLibraryContext = useDefaultLibraryContext();
    const defaultAnalyticsContext = useDefaultAnalyticsContext();

    const queryClient = new QueryClient();

    const { isAuthenticated } = useAuthContext();

    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider>
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <RequireAuth>
                                <LibraryContext.Provider
                                    value={defaultLibraryContext}
                                >
                                    <AnalyticsContext.Provider
                                        value={defaultAnalyticsContext}
                                    >
                                        <MainApp />
                                    </AnalyticsContext.Provider>
                                </LibraryContext.Provider>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/login/*"
                        element={
                            <>
                                {!isAuthenticated ? (
                                    <Routes>
                                        <Route path="/*" element={<Login />} />
                                    </Routes>
                                ) : (
                                    <Navigate to="/" />
                                )}
                            </>
                        }
                    ></Route>
                </Routes>
            </ConfigProvider>
        </QueryClientProvider>
    );
};

export default App;
