import React from 'react';
import { ComplianceItem } from '../types';
import StatusIndicator from './StatusIndicator';
// import useNumberItems from '../hooks/useNumberItems';

interface AssetCardProps {
    item: ComplianceItem;
    imageUrl: string;
    onSelect: (item: ComplianceItem) => void;
    isSelected: boolean;
}

const formatDate = (dateString: string): string => {
    const months: { [key: string]: string } = {
        January: '01',
        February: '02',
        March: '03',
        April: '04',
        May: '05',
        June: '06',
        July: '07',
        August: '08',
        September: '09',
        October: '10',
        November: '11',
        December: '12',
    };

    // Regular expression to extract the date components
    const regex = /(\d{2}):(\d{2}):(\d{2}), (\w+) (\d{2}), (\d{4})/;
    const matches = dateString.match(regex);

    // Ensure that the regex match was successful
    if (matches && matches.length > 5) {
        const month = months[matches[4]];
        const day = matches[5].padStart(2, '0'); // Ensure two digits
        const year = matches[6].slice(-2); // Get last two digits for year

        // Check if the month is a valid month number
        if (!month) {
            console.error('Invalid month name:', matches[4]);
            return 'Invalid date';
        }

        return `${day}/${month}/${year}`;
    } else {
        console.error(
            'Date string does not match expected format:',
            dateString,
        );
        return 'Invalid date';
    }
};

const AssetCard: React.FC<AssetCardProps> = ({
    item,
    imageUrl,
    onSelect,
    isSelected,
}) => {
    // useNumberItems();
    return (
        <div
            className={`rule-result-card ${isSelected ? 'selected' : ''}`}
            onClick={() => onSelect(item)}
        >
            <div className="main-image-container">
                <img
                    className="main-image"
                    src={imageUrl}
                    alt={`Thumbnail for ${item.user_input.asset_name}`}
                />
            </div>
            <div className="info-section">
                <div className="left-side">
                    <p className="project-name">{item.user_input.asset_name}</p>
                    <div className="summary-table">
                        <tr>
                            <td>Country:</td>
                            <td>{item.user_input.country}</td>
                        </tr>
                        <tr>
                            <td>Rules flagged:</td>
                            <td>
                                {item.outcome.rules_flagged}/
                                {item.outcome.rules_passed +
                                    item.outcome.rules_flagged}
                            </td>
                        </tr>
                    </div>
                    <p className="date">
                        {formatDate(item.run_info.upload_time)} by{' '}
                        {item.run_info.user.name}
                    </p>
                </div>
                <div className="right-side">
                    <StatusIndicator
                        decision={item.outcome.decision}
                        isBig={false}
                    />
                    <p className="id">ID: {item.id}</p>
                </div>
            </div>
        </div>
    );
};

export default AssetCard;
