import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Dropdown from '../components/Dropdown';
import UploadBox, { UploadBoxHandles } from '../components/UploadBox';
import { DropdownField, useConfigContext } from '../contexts/ConfigContext';
import Spinner from '../components/Spinner';
import { fetchUserInfo, uploadAsset } from '../utils/backendServices';
import { FileUploadItem, UserInfo } from '../types';
import {
    UploadProgress,
    useUploadProgressContext,
} from '../contexts/UploadProgressContext';

const Upload: FunctionComponent = () => {
    const uploadProgressContext = useUploadProgressContext();
    const uploadButtonRef = useRef<UploadBoxHandles | null>(null);

    const [selectedValues, setSelectedValues] = useState<
        Record<string, string>
    >({});

    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [textFieldValues, setTextFieldValues] = useState<
        Record<string, string>
    >({});
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const configData = useConfigContext();
    const title = configData?.pages.upload.page_title || '';
    const text = configData?.pages.upload.text || '';
    const allDropdownsSelected =
        selectedValues.country && selectedValues.industry;
    const textFieldHasText =
        textFieldValues.asset_name && textFieldValues.asset_name.trim() !== '';

    const canSubmit = allDropdownsSelected && textFieldHasText && selectedFile;

    useEffect(() => {
        configData?.pages.upload.user_input.dropdowns.map(
            (dropdownData: DropdownField) => {
                const defaultValue = dropdownData.default;
                if (defaultValue !== null) {
                    setSelectedValues((prev) => ({
                        ...prev,
                        [dropdownData.name]: defaultValue,
                    }));
                }
            },
        );
    }, [configData]);

    useEffect(() => {
        fetchUserInfo()
            .then((userInfo) => {
                setUserInfo(userInfo);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleSelect = (name: string, value: string) => {
        setSelectedValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleTextFieldChange = (name: string, value: string) => {
        setTextFieldValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleUploadClick = async () => {
        setIsUploading(true);

        if (selectedFile && userInfo) {
            const uploadItem: FileUploadItem = {
                user: { name: userInfo.name, email: userInfo.email },
                image_id: '',
                user_input: {
                    ...selectedValues,
                    ...textFieldValues,
                },
                image_url: URL.createObjectURL(selectedFile),
            };

            const newUploadProgress: UploadProgress = {
                active: true,
                imageUrl: URL.createObjectURL(selectedFile),
                imageId: '',
                timeStamp: new Date(),
                title: textFieldValues.asset_name,
                status: 'queued',
                progress: 0,
                fetchedResult: null,
            };
            uploadAsset(
                selectedFile,
                uploadItem,
                uploadProgressContext.setUploadProgressArray,
                uploadProgressContext.uploadProgressArrayRef,
                newUploadProgress,
            );

            resetAllFields();
            setIsUploading(false);
        } else {
            alert('Please select a file first.');
            setIsUploading(false);
        }
    };

    const submit = () => {
        uploadButtonRef.current?.handleUploadClick();
    };
    const resetAllFields = () => {
        setSelectedFile(null);
        if (configData) {
            configData.pages.upload.user_input.dropdowns.forEach(
                (dropdownData) => {
                    const defaultValue = dropdownData.default;
                    if (defaultValue) {
                        handleSelect(dropdownData.name, defaultValue);
                    } else {
                        handleSelect(dropdownData.name, '');
                    }
                },
            );
            configData.pages.upload.user_input.text_fields.forEach(
                (textfieldData) => {
                    handleTextFieldChange(textfieldData.name, '');
                },
            );
        }
    };

    if (!configData) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spinner />
            </div>
        );
    }

    return (
        <div className="upload-container">
            <div className="shadow-container upload-components">
                <h2 className="upload-title">{title}</h2>
                <p>{text}</p>
                <>
                    <UploadBox
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                        handleUploadClick={handleUploadClick}
                        ref={uploadButtonRef}
                    />
                    {configData.pages.upload.user_input.dropdowns.map(
                        (dropdownData) => {
                            const items = [
                                ...(dropdownData.default === null
                                    ? [
                                          {
                                              value: '',
                                              label: '',
                                              disabled: true,
                                          },
                                      ]
                                    : []),
                                ...dropdownData.choices.map((choice) => ({
                                    value: choice,
                                    label: choice,
                                })),
                            ];
                            return (
                                <div key={dropdownData.name}>
                                    <div className="input-field-title">
                                        {dropdownData.display_name}
                                    </div>
                                    <Dropdown
                                        items={items}
                                        selectedValue={
                                            selectedValues[dropdownData.name] ||
                                            ''
                                        }
                                        onSelect={(value) =>
                                            handleSelect(
                                                dropdownData.name,
                                                value,
                                            )
                                        }
                                        centered={true}
                                    />
                                </div>
                            );
                        },
                    )}
                    {configData.pages.upload.user_input.text_fields.map(
                        (textfieldData) => (
                            <div key={textfieldData.name}>
                                <div className="input-field-title">
                                    {textfieldData.display_name}
                                </div>
                                <input
                                    type="text"
                                    className="user-input-text"
                                    name={textfieldData.name}
                                    value={
                                        textFieldValues[textfieldData.name] ||
                                        ''
                                    }
                                    onChange={(e) =>
                                        handleTextFieldChange(
                                            textfieldData.name,
                                            e.target.value,
                                        )
                                    }
                                />
                            </div>
                        ),
                    )}
                    {canSubmit && !isUploading ? (
                        <div
                            className="submit-button-upload active"
                            onClick={submit}
                        >
                            Click to check image
                        </div>
                    ) : (
                        <div className="submit-button-upload inactive">
                            Click to check image
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};

export default Upload;
