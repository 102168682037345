import React, { FunctionComponent, useEffect } from 'react';
import AnalyticsControlContainer from '../components/AnalyticsControlContainer';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import Spinner from '../components/Spinner';
import Charts from '../components/Charts';
import { useQuery } from '@tanstack/react-query';
import { fetchAndSetAnalyticsData } from '../utils/backendServices';

const Analytics: FunctionComponent = () => {
    const analyticsContext = useAnalyticsContext();

    const { isLoading, refetch } = useQuery({
        queryFn: () => fetchAndSetAnalyticsData(analyticsContext),
        queryKey: ['analyticsData'],
        staleTime: Infinity,
        refetchInterval: 300000,
    });

    useEffect(() => {
        refetch();
    }, [analyticsContext.state.appliedFilters]);
    useEffect(() => {
        analyticsContext.setters.setIsLoading(isLoading);
    }, [isLoading]);

    return (
        <div className="wrapper-container">
            <AnalyticsControlContainer />
            {analyticsContext.state.isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <Spinner title="Loading..." />
                </div>
            ) : (
                <Charts />
            )}
        </div>
    );
};

export default Analytics;
