import React, { useEffect, useState } from 'react';
import { RuleResultExtended, RuleResult } from './../types';
import RuleOutcomeIndicator from './RuleOutcomeIndicator';
import Switch from './ToggleSwitch';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import SearchBar from './SearchBar';
import CircularIcon from './CircularIcon';
import { useConfigContext } from '../contexts/ConfigContext';
import { isMobile } from 'react-device-detect';

interface RuleChecksTableProps {
    rulesArray: RuleResultExtended[];
    onRuleSelected: (selectedRule: RuleResultExtended) => void;
    feedbackActive: boolean;
}

const RuleChecksTable: React.FC<RuleChecksTableProps> = ({
    rulesArray,
    onRuleSelected,
    feedbackActive,
}) => {
    const configData = useConfigContext();
    const [showAll, setShowAll] = useState<boolean>(false);
    const [filteredRules, setFilteredRules] =
        useState<RuleResultExtended[]>(rulesArray);

    useEffect(() => {
        setFilteredRules(rulesArray);
    }, [rulesArray]);

    const handleSearch = (searchTerm: string) => {
        const lowercasedTerm = searchTerm.toLowerCase();
        const filtered = rulesArray.filter((rule) =>
            Object.values(rule).some(
                (value) =>
                    value !== null &&
                    value !== undefined &&
                    value.toString().toLowerCase().includes(lowercasedTerm),
            ),
        );
        setFilteredRules(filtered);
    };

    const displayedRules = showAll
        ? filteredRules
        : filteredRules.filter(
              (rule) => rule.outcome === 'Warning' || rule.outcome === 'Fail',
          );

    let columns = configData
        ? configData.pages.library.asset_details.table
        : [];

    if (isMobile) {
        columns = columns.filter(
            (column) => !['category', 'code', 'conf'].includes(column.name),
        );
    }

    const getRowElement = (
        rule: RuleResult,
        name: keyof RuleResult,
    ): JSX.Element | string => {
        switch (name) {
            case 'outcome':
                return <RuleOutcomeIndicator decision={rule.outcome} />;
            case 'conf':
                return `${(rule.conf * 100).toFixed(2)}%`;
            case 'rule_description':
                return (
                    <>
                        <b>{rule.short_rule_description}</b>
                        <p style={{ margin: '0' }}>{rule.rule_description}</p>
                    </>
                );
            default:
                return rule[name] ? rule[name].toString() : '';
        }
    };

    return (
        <>
            <div className="rules-check-controls">
                <h3>Rule details</h3>
                {!isMobile && (
                    <div className="toggle-container">
                        Fail & Warning
                        <Switch
                            isChecked={showAll}
                            onChange={() => setShowAll(!showAll)}
                        />
                        All
                    </div>
                )}
                <SearchBar onSearch={handleSearch} />
            </div>
            <table className="compliance-table">
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}>{column.display_name}</th>
                        ))}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {displayedRules.map((rule, index) => (
                        <tr
                            key={index}
                            className={index % 2 === 0 ? 'grey-row' : ''}
                        >
                            {columns.map((column, index) => (
                                <td key={index}>
                                    {getRowElement(rule, column.name)}
                                </td>
                            ))}

                            <td>
                                {feedbackActive && (
                                    <CircularIcon
                                        icon={faExclamation}
                                        onClick={() => onRuleSelected(rule)}
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default RuleChecksTable;
