import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import small_logo from './../algospark-logo-small.svg';

import { useConfigContext } from '../contexts/ConfigContext';
import { useLocation, useNavigate } from 'react-router-dom';

interface MobileNavBarProps {
    handleShowUserModal: () => void;
    routes: {
        path: string;
        displayName: string;
        element: React.JSX.Element;
    }[];
}

const MobileNavBar: FunctionComponent<MobileNavBarProps> = ({
    handleShowUserModal,
    routes,
}) => {
    const [showNavMenu, setShowNavMenu] = useState<boolean>(false);

    const location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const configData = useConfigContext();
    const title = configData?.general.title || '';

    const isActive = (path: string) => {
        return location.pathname === path;
    };

    const navigate = useNavigate();

    const handleNavigation = (path: string) => {
        setShowNavMenu(false);
        navigate(path);
    };

    const handleShowNavMenu = () => {
        setShowNavMenu(!showNavMenu);
    };

    const container = useRef<HTMLElement>(null);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        if (container.current) {
            setHeight(container.current.offsetHeight);
        }
    });

    return (
        <>
            <div className="navbar-wrapper">
                <nav className="App-navbar">
                    <div className="nav-title-container">
                        <img
                            src={small_logo}
                            alt="Brand logo"
                            className="brand-logo"
                        />
                        <p className="navbar-title">{title}</p>
                    </div>
                    {!isLoginPage && (
                        <div className="settings-actions mobile">
                            <button
                                className="nav-button mobile-icon"
                                onClick={handleShowUserModal}
                            >
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className="nav-icon-mobile"
                                />
                            </button>
                            <button
                                className="nav-button mobile-icon"
                                onClick={handleShowNavMenu}
                            >
                                <FontAwesomeIcon
                                    icon={faBars}
                                    className="nav-icon-mobile"
                                />
                            </button>
                        </div>
                    )}
                </nav>
            </div>

            {showNavMenu && (
                <div
                    className="mobile-navbar-wrapper"
                    style={{ height: `${height}px` }}
                >
                    <nav className="mobile-navbar" ref={container}>
                        {routes.map((route) => {
                            return (
                                route.path !== '/detail/:id' && (
                                    <button
                                        key={route.path}
                                        onClick={() =>
                                            handleNavigation(route.path)
                                        }
                                        className={`${
                                            isActive(route.path)
                                                ? 'nav-button-mobile selected'
                                                : 'nav-button-mobile'
                                        }`}
                                    >
                                        {route.displayName}
                                    </button>
                                )
                            );
                        })}
                    </nav>
                </div>
            )}
        </>
    );
};

export default MobileNavBar;
