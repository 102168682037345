import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import customer_logo from './../algospark-logo-black-full.svg';
import { useConfigContext } from '../contexts/ConfigContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import MobileNavBar from './MobileNavBar';

interface NavBarProps {
    routes: {
        path: string;
        displayName: string;
        element: React.JSX.Element;
    }[];
    handleShowUserModal: () => void;
}

const NavBar: FunctionComponent<NavBarProps> = ({
    routes,
    handleShowUserModal,
}) => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const configData = useConfigContext();
    const title = configData?.general.title || '';

    const isActive = (path: string) => {
        return location.pathname === path;
    };

    const navigate = useNavigate();

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    return (
        <>
            {isMobile ? (
                <MobileNavBar
                    handleShowUserModal={handleShowUserModal}
                    routes={routes}
                />
            ) : (
                <div className="navbar-wrapper">
                    <nav className="App-navbar">
                        <div className="nav-title-container">
                            <img
                                src={customer_logo}
                                alt="Brand logo"
                                className="brand-logo"
                            />
                            <p className="navbar-title">{title}</p>
                            <p className="navbar-beta">Beta</p>
                        </div>
                        {!isLoginPage && (
                            <div className="settings-actions">
                                {routes.map((route) => {
                                    return (
                                        route.path !== '/detail/:id' && (
                                            <button
                                                key={route.path}
                                                onClick={() =>
                                                    handleNavigation(route.path)
                                                }
                                                className={`${
                                                    isActive(route.path)
                                                        ? 'nav-button-selected'
                                                        : 'nav-button '
                                                }`}
                                            >
                                                {route.displayName}
                                            </button>
                                        )
                                    );
                                })}
                                <button
                                    className="nav-button"
                                    onClick={handleShowUserModal}
                                >
                                    <FontAwesomeIcon icon={faUser} />
                                </button>
                            </div>
                        )}
                    </nav>
                </div>
            )}
        </>
    );
};

export default NavBar;
