import React, { FunctionComponent } from 'react';
import { ComplianceItem, RuleResultExtended, RuleResult } from '../types';
import UserInputTable from './UserInputTable';
import SummaryTable from './SummaryTable';
import RuleChecksTable from './RulesCheckTable';
import TextTable from './TextTable';

interface AssetDetailsInformationProps {
    item: ComplianceItem;
    handleRuleSelected: (selectedRule: RuleResultExtended) => void;
}

const AssetDetailsInformation: FunctionComponent<
    AssetDetailsInformationProps
> = ({ item, handleRuleSelected }) => {
    function getOutcomeOrder(outcome: 'Fail' | 'Warning' | 'Pass') {
        const order = { Fail: 1, Warning: 2, Pass: 3 };
        return order[outcome] || 4; // default order for unknown outcomes
    }

    const compareRules = (a: RuleResult, b: RuleResult) => {
        // Compare by 'outcome' first
        if (getOutcomeOrder(a.outcome) < getOutcomeOrder(b.outcome)) {
            return -1;
        }
        if (getOutcomeOrder(a.outcome) > getOutcomeOrder(b.outcome)) {
            return 1;
        }
        return b.conf - a.conf;
    };

    const rulesResultArray = Object.keys(item.rules_res)
        .map((id) => ({
            rule_id: id,
            ...item.rules_res[id],
        }))
        .sort(compareRules);

    const rulesFailed = rulesResultArray
        .filter((rule) => rule.outcome === 'Fail')
        .map((rule) => {
            let shortRule = rule.short_rule_description;
            if (rule.code !== '') {
                let code = rule.code;
                if (code.startsWith('UK ')) {
                    code = code.slice(3);
                }
                shortRule += ` (${code})`;
            }
            return shortRule;
        });
    const warnings = rulesResultArray
        .filter((rule) => rule.outcome === 'Warning')
        .map((rule) => {
            let shortRule = rule.short_rule_description;
            if (rule.code !== '') {
                let code = rule.code;
                if (code.startsWith('UK ')) {
                    code = code.slice(3);
                }
                shortRule += ` (${code})`;
            }
            return shortRule;
        });
    const extracedText = item.image_data.text;

    return (
        <>
            <hr />
            <UserInputTable
                assetName={item.user_input.asset_name}
                industry={item.user_input.industry}
                country={item.user_input.country}
                user={`${item.run_info.user.name} (${item.run_info.user.email})`}
                uploadTime={item.run_info.upload_time}
                id={item.id}
                rulesFlagged={`${item.outcome.rules_flagged}/${
                    item.outcome.rules_passed + item.outcome.rules_flagged
                }`}
            />
            <hr />
            <SummaryTable rulesFailed={rulesFailed} warnings={warnings} />
            <hr />
            <RuleChecksTable
                rulesArray={rulesResultArray}
                onRuleSelected={handleRuleSelected}
                feedbackActive={
                    item.run_info.user.email !== 'algoadmin@algospark.com' ||
                    item.run_info.user.role === 'View.All'
                }
            />
            <hr />
            <TextTable extractedText={extracedText} />
        </>
    );
};

export default AssetDetailsInformation;
